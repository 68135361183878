<template>
  <b-form-group
    :label="$t('externalLinks')"
    label-for="externalLinks"
    description="something"
  >
    <p v-if="!value || !value.length" class="text-center">
      <small class="text-primary">
        Még nem adtál hozzá hivatkozást
      </small>
    </p>
    <div
      v-for="(link, i) in value"
      :key="i"
      class="d-flex align-items-center mb-3"
    >
      <div class="mr-2">
        <b-form-select
          :options="
            ['community', 'rules', 'about', 'app', 'other'].map((value) => ({
              value,
              text: $t(value)
            }))
          "
          :value="link.type"
          @change="(val) => changeType(i, val)"
        ></b-form-select>
      </div>
      <div class="flex-grow-1">
        <b-form-input
          :value="link.url"
          required
          type="url"
          placeholder="https://example.com"
          @input="(val) => changeUrl(i, val)"
        ></b-form-input>
      </div>
      <div>
        <b-button @click="remove(i)" variant="text" size="sm">
          <b-icon icon="trash" variant="danger" />
        </b-button>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <b-button @click="add" variant="outline-primary">Új hivatkozás</b-button>
    </div>
  </b-form-group>
</template>
<script>
export default {
  props: {
    value: {
      required: true
    }
  },
  methods: {
    add() {
      this.$emit("input", [...(this.value || []), { type: "about", url: "" }]);
    },
    remove(i) {
      const list = [...this.value.slice(0, i), ...this.value.slice(i + 1)];
      this.$emit("input", list.length ? list : undefined);
    },
    changeType(i, type) {
      this.$emit("input", [
        ...this.value.slice(0, i),
        { type, url: this.value[i].url },
        ...this.value.slice(i + 1)
      ]);
    },
    changeUrl(i, url) {
      this.$emit("input", [
        ...this.value.slice(0, i),
        { type: this.value[i].type, url },
        ...this.value.slice(i + 1)
      ]);
    }
  }
};
</script>
