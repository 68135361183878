<template>
  <div class="game-editor">
    <b-container class="py-3">
      <page-title
        :title="createMode ? 'Új játék' : 'Játék szerkesztése'"
      ></page-title>
      <!--div v-if="!createMode">
        <label for="version-selector">Example range with min and max</label>
        <b-form-input
          id="version-selector"
          v-model="version"
          type="range"
          :min="0"
          :max="game.changeRequests.length - 1"
        ></b-form-input>
        <div class="mt-2">Value: {{ version }}</div>
      </div-->
      <b-card style="min-height: 100px">
        <b-overlay
          :show="!localGame || saving"
          no-wrap
          spinner-variant="primary"
        />
        <template v-if="localGame">
          <b-form @reset.prevent="reset" @submit.prevent="reviewModal = true">
            <h4>
              <strong>
                Alapadatok
              </strong>
            </h4>

            <b-row>
              <b-col cols="12" sm="6">
                <b-form-group
                  :label="$t('name')"
                  label-for="name"
                  description="A játék neve. Legyen tömör, informatív, könnyen felismerhető!"
                >
                  <b-form-input
                    id="name"
                    required
                    v-model="localGame.name"
                    placeholder="Aa"
                    trim
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <b-form-group
                  :label="$t('path')"
                  label-for="path"
                  description="Automatikusan generálva a létrehozáskor, azonos nevek esetén jóváhagyási sorrendben szám kerül a végére."
                >
                  <b-form-input disabled :value="path"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-form-group
              :label="$t('url')"
              label-for="url"
              description="Teljes link, http:// vagy https://-rel kell kezdődnie."
            >
              <b-form-input
                id="url"
                :required="!noLink"
                type="url"
                v-model="localGame.url"
                placeholder="https://example.com"
                trim
                :disabled="noLink"
              ></b-form-input>
            </b-form-group>

            <b-form-group description="Használd felelősséggel!">
              <b-form-checkbox id="no-link" v-model="noLink" name="no-link">
                Nem tartozik központi link a játékhoz
              </b-form-checkbox>
            </b-form-group>

            <!--h4>
              <strong>
                Képek
              </strong>
            </h4>

            <b-row>
              <b-col cols="12" md="6">
                <b-form-file accept="image/*" v-model="file" />
              </b-col>
            </b-row-->

            <h4>
              <strong>
                Részletek
              </strong>
            </h4>

            <b-form-group
              :label="$t('lead')"
              label-for="lead"
              description="Rövid, 1-2 mondatos leírás, ami a listanézetben jelenik meg, megkönnyítve a játék azonosítását (tartalmazhat kulcsszavakat, más neveit a játéknak stb). Max. 150 karakter."
            >
              <b-form-textarea
                id="description"
                v-model="localGame.lead"
                placeholder="Aa"
                required
                rows="2"
                no-resize
                :formatter="
                  (val) =>
                    val.length && val.length < 150 ? val : val.substring(0, 150)
                "
                trim
              ></b-form-textarea>
              <p class="m-0 text-right">
                <small class="text-muted">
                  {{ (localGame.lead && localGame.lead.length) || 0 }}/150
                </small>
              </p>
            </b-form-group>

            <b-form-group
              :label="$t('description')"
              label-for="description"
              description="Formázható, tetszőleges leírás, tartalmazhat minden olyan infót amit nem tudsz külön mezőbe beírni. Legyen tárgyilagos, tömör, de nincs karakterkorlát."
            >
              <richtext-editor v-model="localGame.description" />
            </b-form-group>

            <h4>
              <strong>
                Tulajdonságok
              </strong>
            </h4>

            <b-form-group :label="$t('tags')" label-for="tags">
              <tags-editor v-model="localGame.tags" />
            </b-form-group>

            <b-row>
              <b-col cols="12" md="4">
                <b-form-group
                  :label="$t('ageLimit')"
                  label-for="ageLimit"
                  description="Ajánlott minimum életkor"
                >
                  <b-form-input
                    id="ageLimit"
                    type="number"
                    v-model="localGame.ageLimit"
                    placeholder="123"
                    min="0"
                    max="100"
                    step="1"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="4">
                <player-count-input v-model="localGame.playerCount" />
              </b-col>
              <b-col cols="12" md="4">
                <b-form-group
                  :label="$t('playTime')"
                  label-for="playTime"
                  description="A legrövidebb értelmezhető játékidő (pl. 1 kör hossza)"
                >
                  <b-form-input
                    id="playTime"
                    type="number"
                    v-model="localGame.playTime"
                    placeholder="123"
                    min="5"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <pricing-input v-model="localGame.pricing" />

            <b-row>
              <b-col cols="12" md="3">
                <b-form-group
                  :label="$t('hunLocalized')"
                  label-for="hunLocalized"
                  description="A játék (egész felület vagy csak pakli) magyarítva van"
                >
                  <b-select
                    v-model="localGame.hunLocalized"
                    :options="options.boolean"
                  >
                  </b-select>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="3">
                <b-form-group
                  :label="$t('registeredUserNeeded')"
                  label-for="registeredUserNeeded"
                  description="Igen: CSAK regisztrált felhasználó tud játszani. Ajánlott: bizonyos hasznos funkcionalitások regisztrációhoz vannak kötve."
                >
                  <b-select
                    v-model="localGame.registeredUserNeeded"
                    :options="options.trilean"
                  >
                  </b-select>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="3">
                <b-form-group
                  :label="$t('groupNeeded')"
                  label-for="groupNeeded"
                  description="Igen: nem tudsz játszani, ha nem társasággal keresed a játékot. Ajánlott: könnyebb a játék, ha a barátaiddal játszol."
                >
                  <b-select
                    v-model="localGame.groupNeeded"
                    :options="options.trilean"
                  >
                  </b-select>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="3">
                <b-form-group
                  :label="$t('meetNeeded')"
                  description="Igen: a játék nem oldja meg a kommunikációt. Ajánlott: van a játékon belül kommunikáció, de érdemes inkább külső eszközt (chat, video- vagy hanghívás) használni."
                >
                  <b-select
                    v-model="localGame.meetNeeded"
                    :options="options.trilean"
                  >
                  </b-select>
                </b-form-group>
              </b-col>
            </b-row>

            <h4>
              <strong>
                Egyéb
              </strong>
            </h4>

            <b-form-group
              label="Platformok"
              v-slot="{ ariaDescribedby }"
              description="A játék ezeken a platformokon érhető el. Böngészőben futó játékokhoz a Web-et pipáld be, a többit csak akkor, ha az az adott platformon telepíthető, natív alkalmazásként fut a játék."
            >
              <b-form-checkbox-group
                id="checkbox-group-1"
                v-model="localGame.platforms"
                :aria-describedby="ariaDescribedby"
              >
                <b-form-checkbox
                  v-for="p in [
                    'Web',
                    'Windows',
                    'MacOs',
                    'Linux',
                    'Android',
                    'iOS'
                  ]"
                  :key="p"
                  :value="p"
                >
                  {{ p }}
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>

            <external-links-input v-model="localGame.externalLinks" />

            <b-row>
              <b-col cols="12" md="6">
                <b-form-group :label="$t('pro')">
                  <string-list-input v-model="localGame.pro" />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group :label="$t('contra')">
                  <string-list-input v-model="localGame.contra" />
                </b-form-group>
              </b-col>
            </b-row>
            <p class="text-muted">
              <small>
                A játék pozitívumai és negatívumai. Vonatkozhatnak a
                játékmechanikára (játék alapötlete, működése), és a
                megvalósításra (gyors/lassú, jól dizájnolt, hű az eredeti
                ötlethez stb.) Igyekezz elfogulatlanul, tárgyilagosan
                fogalmazni!
              </small>
            </p>

            <b-form-group label="Címkék" v-if="false">
              <tags-editor />
            </b-form-group>

            <b-button
              type="submit"
              variant="primary"
              :disabled="!request.fields.length"
            >
              Mentés
            </b-button>
            <b-button variant="outline-danger" class="ml-2" @click="goBack">
              Vissza
            </b-button>
          </b-form>
        </template>
      </b-card>
    </b-container>
    <b-modal
      v-model="reviewModal"
      title="Áttekintés"
      @ok="save"
      ok-title="Mentés"
      cancel-title="Mégse"
      size="lg"
    >
      <field v-for="field in request.fields" :key="field.name" :field="field" />
      <b-form-group
        label="Megjegyzés"
        label-for="comment"
        description="Ide írhatod a moderátoroknak szóló egyéb megjegyzésedet. Csak a módosítási kérelemnél jelenik meg, a játék oldalán nem."
      >
        <b-form-textarea
          id="comment"
          v-model="comment"
          placeholder="Aa"
          rows="2"
          no-resize
          trim
        ></b-form-textarea>
      </b-form-group>
    </b-modal>
  </div>
</template>
<script>
import ExternalLinksInput from "../components/inputs/ExternalLinksInput.vue";
import PlayerCountInput from "../components/inputs/PlayerCountInput.vue";
import PageTitle from "../components/PageTitle.vue";
import RichtextEditor from "../components/RichtextEditor.vue";
import GameModuleMixin from "../mixins/GameModuleMixin";
import AuthMixin from "../mixins/AuthMixin";
import StringListInput from "../components/inputs/StringListInput.vue";
import PricingInput from "../components/inputs/PricingInput.vue";
import title2path from "@/utils/title2path";
import Field from "../components/request_fields/Field.vue";
import TagsEditor from "../components/TagsEditor.vue";
var cloneDeep = require("lodash.clonedeep");

export default {
  name: "GameEditor",
  components: {
    PageTitle,
    RichtextEditor,
    PlayerCountInput,
    ExternalLinksInput,
    StringListInput,
    PricingInput,
    Field,
    TagsEditor
  },
  mixins: [GameModuleMixin, AuthMixin],
  data: () => ({
    localGame: null,
    requiredRole: "user",
    reviewModal: false,
    comment: null,
    saving: false,
    noLink: false
  }),
  mounted() {
    this.copyInitialGame();
  },
  computed: {
    path() {
      return this.localGame.path || title2path(this.localGame.name);
    },
    options() {
      return {
        boolean: [
          { text: this.$t("yes"), value: true },
          { text: this.$t("no"), value: false }
        ],
        trilean: [
          { text: this.$t("yes"), value: "yes" },
          { text: this.$t("no"), value: "no" },
          { text: this.$t("recommended"), value: "recommended" }
        ]
      };
    },
    createMode() {
      return !this.gamePath;
    },
    game() {
      return this.gameModuleItems.find(
        (g) => g.content && g.content.path === this.gamePath
      );
    },
    initialGame() {
      return this.game ? this.game.content || {} : {};
    },
    gamePath() {
      return this.$route.params.gamePath || null;
    },
    request() {
      const request = { fields: [] };
      const keys = Object.keys(this.initialGame || {})
        .concat(Object.keys(this.localGame || {}))
        .filter((value, index, self) => self.indexOf(value) === index);

      if (this.localGame) {
        for (const key of keys) {
          if (
            JSON.stringify(this.localGame[key]) !==
            JSON.stringify(this.initialGame[key])
          )
            request.fields.push({
              name: key,
              valueFrom: this.initialGame[key] || null,
              valueTo: this.localGame[key]
            });
        }
      }
      return request;
    }
  },
  methods: {
    copyInitialGame() {
      this.localGame = cloneDeep(this.initialGame);
      if (!this.createMode && !this.localGame.url && !!this.game)
        this.noLink = true;
    },
    async save() {
      console.log(JSON.stringify({ ...this.request, comment: this.comment }));
      try {
        this.saving = true;
        if (!this.gamePath) {
          await this.createGame({ ...this.request, comment: this.comment });
        } else {
          await this.updateGame({
            gameId: this.game.id,
            data: { ...this.request, comment: this.comment }
          });
        }
        this.$root.$bvToast.toast(
          "Javaslat beküldve. Értesítettük a moderátorokat, nézz vissza kis idő múlva!",
          {
            title: `Siker`,
            variant: "success",
            solid: true
          }
        );
        await this.goBack(true);
      } catch (error) {
        console.log(error);
        this.$root.$bvToast.toast("Nem sikerült a mentés", {
          title: `Hiba`,
          variant: "danger",
          solid: true
        });
      } finally {
        this.saving = false;
      }
    },
    async goBack(force = false) {
      if (
        !force &&
        this.request.fields.length &&
        !(await this.$bvModal.msgBoxConfirm(
          "Elmentetlen módosításaid vannak. Biztosan visszalépsz?",
          {
            title: "Megerősítés",
            okTitle: "Igen",
            cancelTitle: "Mégse",
            centered: true
          }
        ))
      )
        return;
      this.$router.go(-1);
    },
    reset() {
      alert("reset");
    }
  },
  watch: {
    initialGame() {
      this.copyInitialGame();
    },
    async noLink(value) {
      let confirmed = false;
      const confirmNeeded = value;
      if (confirmNeeded) {
        try {
          confirmed = await this.$bvModal.msgBoxConfirm(
            "A 'Nincs link' opciót csak akkor használd, ha olyan többplatformos (Android/iOS) alkalmazásról van szó, aminek nincs egy központi weboldala, és nem szeretnél választani az App linkek közül egy kiemeltet, vagy ha valóban nem tartozik link a játékhoz (pl. videohívásban játszható szójáték). Biztosan ezt szeretnéd?",
            {
              title: "Megerősítés",
              okTitle: "Igen",
              cancelTitle: "Mégse",
              centered: true
            }
          );
          if (confirmed) {
            this.localGame.url = null;
            delete this.localGame.url;
          }
        } catch (error) {
          console.log(error);
        }
      }
      this.noLink = confirmed;
    }
  }
};
</script>
