<template>
  <b-form-tags
    :value="value"
    no-outer-focus
    class="mb-2 form-tags-wrapper"
    @input="(v) => $emit('input', v)"
    add-on-change
  >
    <template v-slot="{ tags, inputAttrs, inputHandlers, addTag, removeTag }">
      <b-input-group aria-controls="my-custom-tags-list">
        <input
          id="tags"
          v-bind="inputAttrs"
          v-on="inputHandlers"
          placeholder="Új címke - nyomj entert a hozzáadáshoz"
          class="form-control"
          list="my-list-id"
        />
        <b-input-group-append>
          <b-button @click="addTag()" variant="primary" :disabled="!inputAttrs.value">
            <b-icon-plus />
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <datalist id="my-list-id">
        <option v-for="tag in availableTags" :key="tag.text" :value="tag.text"
          >{{ tag.count }} játék</option
        >
      </datalist>
      <div class="mt-2">
        {{ tags.length }} címke
        <b-tag v-for="tag in tags" :key="tag" @remove="removeTag(tag)" class="mb-1">
          #{{ tag }}
        </b-tag>
      </div>
      <!-- <ul
        id="my-custom-tags-list"
        class="list-unstyled d-inline-flex flex-wrap mb-0"
        aria-live="polite"
        aria-atomic="false"
        aria-relevant="additions removals"
      >
        <b-card
          v-for="tag in tags"
          :key="tag"
          :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
          tag="li"
          class="mt-1 mr-1"
          body-class="py-1 pr-2 text-nowrap"
        >
          <strong>{{ tag }}</strong>
          <b-button
            @click="removeTag(tag)"
            variant="link"
            size="sm"
            :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
            >remove</b-button
          >
        </b-card>
      </ul> -->
    </template>
  </b-form-tags>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    value: {
      required: true
    }
  },
  data: () => ({}),
  methods: {
    rnd() {
      return Math.floor(Math.random() * 100);
    }
  },
  computed: {
    ...mapGetters("gameModule", ["gameTags"]),
    availableTags() {
      return this.gameTags.filter((t) => !this.value || !this.value.includes(t.text));
    }
  }
};
</script>
<style lang="scss" scoped>
.form-tags-wrapper {
  padding: 0;
  border: none;
}
</style>
