<template>
  <div>
    <p v-if="!value || !value.length" class="text-center">
      <small class="text-primary">
        Nincs egy elem sem
      </small>
    </p>
    <div v-for="(e, i) in value" :key="i" class="d-flex align-items-center mb-3">
      <div class="flex-grow-1">
        <b-form-input
          :value="e"
          required
          type="text"
          placeholder="Aa"
          @input="(val) => change(i, val)"
        ></b-form-input>
      </div>
      <div>
        <b-button @click="remove(i)" variant="text" size="sm">
          <b-icon icon="trash" variant="danger" />
        </b-button>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <b-button @click="add" variant="outline-primary">Új elem</b-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      required: true
    }
  },
  methods: {
    add() {
      this.$emit("input", [...(this.value || []), ""]);
    },
    remove(i) {
      const list = [...this.value.slice(0, i), ...this.value.slice(i + 1)];
      this.$emit("input", list.length ? list : undefined);
    },
    change(i, elem) {
      this.$emit("input", [
        ...this.value.slice(0, i),
        elem,
        ...this.value.slice(i + 1)
      ]);
    }
  }
};
</script>
